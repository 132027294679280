import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapExpandControlComponent } from './map-expand-control.component';
import { MapControlContainerModule } from '../map-control-container/map-control-container.module';



@NgModule({
  declarations: [MapExpandControlComponent],
  exports: [MapExpandControlComponent],
  imports: [
    MapControlContainerModule,
  ]
})
export class MapExpandControlModule { }
