<app-common-layout [logoContentRef]="hcpCompany || isShowExpiredPlug() || loading ? logoRef : null">
  <ng-template #logoRef>
    <div *ngIf="hcpCompany && !isShowExpiredPlug()" class="logo-message">
      Your job with {{ driver?.name || 'Unknown driver' }} of {{ hcpCompany?.name || 'Unknown Company' }}
    </div>
  </ng-template>
  <div class="map-wrapper">
    <google-map [options]="mapOptions" (mapDragstart)="onMapDragStart()" width="100%" height="100%">
      <app-map-zoom-controls (pressed)="onZoomControlPressed()">
        <button class="map-control-btn" (click)="onRecenterMapPressed()">
          <i class="icon-target"></i>
        </button>
      </app-map-zoom-controls>

      <app-map-expand-control></app-map-expand-control>

      <app-polyline
        [encoded]="trip?.encodedPolyline"
        [color]="'brand-1'| color">
      </app-polyline>

      <ng-template [ngIf]="trip?.completed || !trip" [ngIfElse]="tplEnroutePoint">
        <ng-container *ngIf="(parkedIconOpts$ | async) as parkedIconOpts">
          <map-marker *ngIf="vehicle?.latLng as latLng"
            [position]="latLng"
            [options]="parkedIconOpts"
          ></map-marker>
        </ng-container>

      </ng-template>

      <ng-template #tplEnroutePoint>
        <app-vehicle-enroute-icon [shareId]="shareId" [latLng]="vehicle?.latLng" [heading]="vehicle?.heading || 0" ></app-vehicle-enroute-icon>
      </ng-template>

      <map-marker *ngIf="trip?.startLatLng as latLng"
        [position]="latLng"
        [options]="tripsStartOpts"
      ></map-marker>

      <map-marker *ngIf="destination?.latLng as destinationLatLng"
        [position]="destinationLatLng"
        [options]="destinationOpts"
      ></map-marker>
    </google-map>

    <button *ngIf="expirationTime" class="button-vehicle-details hide-mob-up" [appTrackEvent]="'Web-EndUser-DetailsCard-Tapped'" (click)="isVehicleCardOpen = true">Details</button>

    <div class="vehicle-card-wrapper" *ngIf="expirationTime" [class.open]="isVehicleCardOpen" [class.close]="!isVehicleCardOpen" (click)="isVehicleCardOpen = false">
      <app-vehicle-card-details
        [isHCPDriver]="isHCPDriver"
        [shareId]="shareId"
        [driver]="driver"
        [vehicle]="vehicle"
        [expirationTime]="expirationTime"
        [hasTrip]="!!trip"
        [isTripCompleted]="!trip || trip?.completed"
        [startAddress]="trip?.startAddress"
        [endAddress]="!trip ? vehicle?.address : trip?.endAddress"
        [jobStartTime]="plannedArrivalTime"
        [jobAddress]="destination?.address"
        [hcpCompany]="hcpCompany"
      ></app-vehicle-card-details>
    </div>
  </div>
</app-common-layout>

<div *ngIf="loading" class="blackout">
  <div class="spinner"></div>
  <div class="blackout-title">Locating vehicle...</div>
</div>

<div *ngIf="isShowExpiredPlug()" class="blackout">
  <i class="blackout-icon icon-warning"></i>
  <div class="blackout-title">The link is no longer active.</div>
  <div class="blackout-subtitle">Please reach out to the business if you need to track the vehicle.</div>
</div>
<div *ngIf="errorMessage" class="blackout">
  <i class="blackout-icon icon-warning"></i>
  <div class="blackout-title">An error occurred</div>
  <div class="blackout-subtitle">{{errorMessage}}</div>
</div>
