import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DriverModel } from 'src/app/core/models/driver.model';
import { HCPCompany } from 'src/app/core/models/shared-data.model';
import { VehicleModel } from 'src/app/core/models/vehicle.model';

@Component({
  selector: 'app-vehicle-card-details',
  templateUrl: './vehicle-card-details.component.html',
  styleUrls: ['./vehicle-card-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleCardDetailsComponent {
  @Input()
  public isHCPDriver = false;

  @Input()
  public hasTrip: boolean | undefined = false;

  @Input()
  public isTripCompleted: boolean | undefined = false;

  @Input()
  public vehicle: VehicleModel | undefined;

  @Input()
  public driver: DriverModel | undefined;

  @Input()
  public expirationTime: StringOrDateOrNull;

  @Input()
  public jobStartTime: StringOrDateOrNull;

  @Input()
  public startAddress: string | undefined;

  @Input()
  public endAddress: string | undefined;

  @Input()
  public jobAddress: string | undefined;

  @Input()
  public shareId: string | undefined;

  @Input()
  public hcpCompany: HCPCompany | undefined;

  public stopPropagation(e: MouseEvent): void {
    e.stopPropagation();
  }
}
