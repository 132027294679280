import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AnalyticsService } from './core/analytics/analytics.service';

@Component({
  selector: 'app-root',
  template: '<app-home></app-home>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(private analytics: AnalyticsService) {
  }


  public ngOnInit(): void {
    this.analytics.initialize();
  }
}
