import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractLoader } from '../abstract.loader';
import { Observable } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';
import { cutWatermark } from 'src/app/utils/load-image';

@Injectable({
    providedIn: 'root',
})
export class ImageLoader extends AbstractLoader {
  private vehicleImgCache = new Map<string, Observable<string>>();

  constructor(private readonly http: HttpClient) {
    super();
  }

  /**
   * Load vehicle img from endpoint
   * @param shareId - string
   * @returns base64 img data
   */
  public loadVehicleImg(shareId: string): Observable<string> {
    if (!this.vehicleImgCache.has(shareId)) {
      this.vehicleImgCache.set(
        shareId,
        this.http.get<string>(this.getUrl('{apiUrl}/publiclinksaccess/{shareId}/images/vehicle', { shareId }))
          .pipe(
            switchMap((base64ImgData) => cutWatermark(base64ImgData)),
            shareReplay(),
          ));
    }

    return this.vehicleImgCache.get(shareId) as Observable<string>;
  }

  public loadDriverImg(shareId: string): Observable<string> {
    return this.http.get<string>(this.getUrl('{apiUrl}/publiclinksaccess/{shareId}/images/driver', { shareId }));
  }
}
