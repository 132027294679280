import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class DateformatService {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  public formatDate(value: any, format: string): string {
    if (!value) {
      return '----';
    }

    if (hasTimezone(value)) {
      const timeZone = value.substr(-6);
      const displayValue = value.substr(0, 19);
      return formatDate(displayValue, this.getFormat(format), this.locale);
      // return `${formatDate(displayValue, this.getFormat(format), this.locale)} (${getTimeZoneName(timeZone)})`;
    }

    try {
      return formatDate(value, this.getFormat(format), this.locale);
    } catch {
      return 'Invalid date';
    }
  }

  private getFormat(format: string): string {
    return format;
  }
}

// eg: -07:00
function getTimeZoneName(timeZone: string): string {
  const [hours, minutes] = timeZone.split(':').map((item) => parseInt(item, 10));
  return `UTC ${hours >= 0 ? '+' : ''}${hours}${minutes ? ':' + minutes : ''}`;
}

function hasTimezone(dateString: string): boolean {
  return /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}[-|+]\d{2}:\d{2}$/.test(dateString);
}
