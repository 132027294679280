import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MapIconLoader } from 'src/app/core/loaders/map-icon/map-icon.loader';
import { LatLng } from 'src/app/core/models/common.model';

@Component({
  selector: 'app-vehicle-enroute-icon',
  templateUrl: './vehicle-enroute-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleEnrouteIconComponent implements OnInit {
  @Input()
  public latLng: LatLng | undefined;

  @Input()
  public heading = 0;

  @Input()
  public shareId: string | undefined;

  public mapIcon$!: Observable<google.maps.MarkerOptions>;

  // public circleOptions: google.maps.MarkerOptions = {
  //   icon: {
  //     path: google.maps.SymbolPath.CIRCLE,
  //     scale: 10,
  //     strokeColor: '#65BE02',
  //     fillColor: '#65BE02',
  //     fillOpacity: 1,
  //   },
  // };

  constructor(private mapIconLoader: MapIconLoader) {

  }

  public ngOnInit(): void {
    if (this.shareId) {
      this.mapIcon$ = this.mapIconLoader.loadVehicleEnrouteIcon(this.shareId, this.heading);
    }
  }

  // public arrowShapeOpt(): google.maps.MarkerOptions {
  //   return {
  //     icon: {
  //       path: 'm28.2,29.305-6.43,-17.851-5.57,18.082l5.842,-6.63l6.155,6.39l0.001,-0.0003z',
  //       fillOpacity: 1,
  //       fillColor: '#FFF',
  //       strokeColor: '#FFF',
  //       rotation: this.heading,
  //       anchor: new google.maps.Point(22, 22),
  //     }
  //   };
  // }
}
