const DEFAULT_PARSE_REGEXP = /\{([^\}]+)?\}/g;
/**
 * this is a primitive template engine that can iterpolate var inside braces {varName}
 */
export function interpolateStr(tpl: string, data: { [propName: string]: any }, parseRegexp?: RegExp): string {
  const re = parseRegexp || DEFAULT_PARSE_REGEXP;
  let resultTpl = tpl;
  let match;
  // tslint:disable-next-line: no-conditional-assignment
  while ((match = re.exec(resultTpl))) {
    resultTpl = resultTpl.replace(match[0], data[match[1]] || '');
  }
  return resultTpl;
}
