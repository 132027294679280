import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { ImageLoader } from 'src/app/core/loaders/image/image.loader';

@Component({
  selector: 'app-img-vehicle-with-driver',
  templateUrl: './img-vehicle-with-driver.component.html',
  styleUrls: ['./img-vehicle-with-driver.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImgVehicleWithDriverComponent implements OnInit, OnDestroy {
  @Input()
  public hasDriver = false;

  @Input()
  public shareId: string | undefined;

  // Vehicle img
  public vehicleImg: string | undefined;
  public isVehicleImgLoading = true;
  public isVehicleImgHasError = false;

  // Driver img
  public driverImg: string | undefined;
  public isDriverImgLoading = true;
  public isDriverImgHasError = false;

  private onDestroy$ = new Subject<void>();

  constructor(
    private imageLoader: ImageLoader,
    private chRef: ChangeDetectorRef,
  ) { }

  public ngOnInit(): void {
    if (this.shareId) {
      this.loadVehicleImg(this.shareId);
      if (this.hasDriver) {
        this.loadDriverImg(this.shareId);
      }
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  private loadVehicleImg(shareId: string): void {
    this.imageLoader.loadVehicleImg(shareId)
      .pipe(
        takeUntil(this.onDestroy$),
        finalize(() => this.chRef.markForCheck()),
      )
      .subscribe({
        next: (data) => this.vehicleImg = data,
        error: () => this.isVehicleImgHasError = true,
      });
  }

  private loadDriverImg(shareId: string): void {
    this.imageLoader.loadDriverImg(shareId)
      .pipe(
        takeUntil(this.onDestroy$),
        finalize(() => this.chRef.markForCheck()),
      )
      .subscribe({
        next: (data) => {
          this.driverImg = data;
          this.isDriverImgLoading = false;
        },
        error: () => this.isDriverImgHasError = true,
      });
  }
}
