import { Observable, Observer, of, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

const cache = new Map<string, ReplaySubject<HTMLImageElement>>();

export interface IDrawImageOptions {
  maxWidth?: number;
  maxHeight?: number;
  rotation?: number;
  sourceHeight?: number;
  sourceWidth?: number;
}

export function loadImage(src: string): Observable<HTMLImageElement> {
  if (!cache.has(src)) {
    const subj = new ReplaySubject<HTMLImageElement>();
    cache.set(src, subj);
    new Observable((observer: Observer<HTMLImageElement>) => {
      const img = new Image();
      img.onload = () => {
        observer.next(img);
        observer.complete();
      };
      img.onerror = (e) => observer.error(e);
      img.src = src;
    }).subscribe(subj);
  }

  return cache.get(src) as Observable<HTMLImageElement>;
}

export function loadImgIntoCanvas(src: string, options?: IDrawImageOptions): Observable<HTMLCanvasElement> {
  return loadImage(src).pipe(map((img) => drawIntoCanvas(img, options)));
}

export function drawIntoCanvas(
  source: HTMLImageElement | HTMLVideoElement,
  options: IDrawImageOptions = {},
): HTMLCanvasElement {
  const { maxHeight, maxWidth, rotation, sourceHeight, sourceWidth } = options;
  const sw = sourceWidth || source.width;
  const sh = sourceHeight || source.height;
  const scaleW = maxWidth ? maxWidth / sw : 1;
  const scaleH = maxHeight ? maxHeight / sh : 1;
  const scale = maxHeight ? Math.min(scaleW, scaleH) : scaleW;

  const canvas = document.createElement('canvas');

  canvas.width = sw * scale;
  canvas.height = sh * scale;

  if (rotation) {
    const ctx = canvas.getContext('2d');
    if (ctx) {
      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;
      ctx.save();
      ctx.translate(centerX, centerY);
      ctx.rotate(degToRad(rotation));
      ctx.translate(-centerX, -centerY);
      ctx.drawImage(source, (canvas.width - sw) / 2, (canvas.height - sh) / 2);
      ctx.restore();
    }
  } else {
    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.drawImage(source, 0, 0, canvas.width, canvas.height);
    }
  }

  return canvas;
}

export function degToRad(degrees: number): number {
  return (degrees * Math.PI) / 180;
}

export function cutWatermark(base64Img: string): Observable<string> {
  if (!base64Img) {
    return of(base64Img);
  }

  return new Observable((subscriber) => {
    const image = new Image();

    image.onload = (): void => {
      const width = image.width;
      const height = image.height;
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');

      if (ctx) {
        ctx.drawImage(image, 0, 0, width, height);
        // cut 10% from bottom of image
        ctx.clearRect(0, height - height * 0.1, width, height);
      }

      subscriber.next(canvas.toDataURL());
      subscriber.complete();
    };

    image.onerror = (e) => {
      subscriber.error(e);
    };

    image.src = base64Img;
  });
}
