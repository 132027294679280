import { Pipe, PipeTransform } from '@angular/core';
import { DateformatService } from './dateformat.service';

@Pipe({
  name: 'dateformat',
})
export class DateformatPipe implements PipeTransform {
  constructor(private readonly dateformatService: DateformatService) {}

  transform(value: any, format: string): string {
    return this.dateformatService.formatDate(value, format);
  }
}
