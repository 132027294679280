import { Injectable } from '@angular/core';
import { SegmentService } from 'ngx-segment-analytics';

export type TAnalyticsEvents = 'Web-EndUser-DetailsCard-Tapped';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private segment: SegmentService) { }

  public initialize(): void {

  }

  public trackEvent(event: TAnalyticsEvents): void {
    this.segment.track(event);
  }
}
