<div class="common-layout">
  <div class="logo">
    <ng-template [ngIf]="!logoContentRef" [ngIfElse]="logoContentRef">
      <div>
        <img class="icon-logo" src="assets/logo.svg">
      </div>
      <div class="logo-label">Shared Location</div>
    </ng-template>
  </div>

  <div class="main-content">
    <ng-content></ng-content>
  </div>

  <ng-content select="out-of-content"></ng-content>
</div>
