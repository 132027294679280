import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { HomeModule } from './view/home/home.module';
import { ApiInterceptor } from './core/api/api-interceptor';
import { SegmentModule } from 'ngx-segment-analytics';

import { environment } from '../environments/environment';
import { NgxMaskModule } from 'ngx-mask';

const SEGMENT_KEY_PROD = 'O3sl1dv2ZHQJO9A0Lf0s32aRS9FNaUzA';
const SEGMENT_KEY_STG = 'Dyi1pJI0vLFcs8l0xGasLfgJwbpODJcW';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    HomeModule,
    SegmentModule.forRoot({
      apiKey: environment.production ? SEGMENT_KEY_PROD : SEGMENT_KEY_STG,
      debug: !environment.production,
      loadOnInitialization: true,
    }),
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
