import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonLayoutComponent } from './common-layout.component';



@NgModule({
  declarations: [CommonLayoutComponent],
  exports: [CommonLayoutComponent],
  imports: [
    CommonModule
  ]
})
export class CommonLayoutModule { }
