import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-polyline',
  template: '<map-polyline *ngIf="polylinePoints" [options]="opts" [path]="polylinePoints"></map-polyline>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolylineComponent implements OnChanges {
  @Input()
  public encoded: string | undefined;

  @Input()
  public color: string | undefined;

  public polylinePoints: google.maps.LatLng[] | null = null;

  public opts: google.maps.PolylineOptions = {};

  constructor() { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.encoded) {
      if (this.encoded) {
        this.polylinePoints = google.maps.geometry.encoding.decodePath(this.encoded);
      } else {
        this.polylinePoints = null;
      }
    }

    if (changes.color) {
      this.opts = this.color ? {
        strokeColor: this.color
      } : {};
    }
  }
}
