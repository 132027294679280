import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './pages/home/home.component';
import { CommonLayoutModule } from 'src/app/shared/layouts/common-layout/common-layout.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { VehicleCardDetailsComponent } from './components/vehicle-card-details/vehicle-card-details.component';
import { MapZoomControlsModule } from 'src/app/shared/maps/map-zoom-controls/map-zoom-controls.module';
import { MapExpandControlModule } from 'src/app/shared/maps/map-expand-control/map-expand-control.module';
import { VehicleEnrouteIconModule } from 'src/app/shared/maps/icons/vehicle-enroute-icon/vehicle-enroute-icon.module';
import { PolylineModule } from 'src/app/shared/maps/polyline/polyline.module';
import { TimeRemainingModule } from 'src/app/shared/pipes/time-remaining/time-remaining.module';
import { ColorModule } from 'src/app/shared/pipes/color/color.module';
import { ImageModule } from 'src/app/shared/modules/image/image.module';
import { ImgVehicleWithDriverComponent } from './widgets/img-vehicle-with-driver/img-vehicle-with-driver.component';
import { DirectivesCommonModule } from 'src/app/shared/directives/common/common.module';
import { NgxMaskModule } from 'ngx-mask';
import { DateformatModule } from 'src/app/shared/pipes/dateformat/dateformat.module';

@NgModule({
  declarations: [HomeComponent, VehicleCardDetailsComponent, ImgVehicleWithDriverComponent],
  exports: [HomeComponent],
  imports: [
    CommonModule,
    CommonLayoutModule,
    GoogleMapsModule,
    MapZoomControlsModule,
    MapExpandControlModule,
    PolylineModule,
    VehicleEnrouteIconModule,
    TimeRemainingModule,
    ColorModule,
    ImageModule,
    DirectivesCommonModule,
    NgxMaskModule,
    DateformatModule,
  ]
})
export class HomeModule { }
