import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[appImage]'
})
export class ImageDirective implements OnChanges {

  @Input()
  public appImage: string | undefined | null;

  @Input()
  public errorSrc: string | undefined | null;

  @Output()
  public loaded = new EventEmitter<void>();

  @Output()
  public failed = new EventEmitter<void>();

  constructor(private elRef: ElementRef<HTMLImageElement>, private chRef: ChangeDetectorRef) { }

  public ngOnChanges(sc: SimpleChanges): void {
    if (sc.appImage && sc.appImage.currentValue) {
      this.loadImage(sc.appImage.currentValue);
    }
  }

  private loadImage(src: string): void {

    if (!this.elRef.nativeElement) {
      return;
    }

    this.elRef.nativeElement.onload = () => {
      this.loaded.emit();
    };

    this.elRef.nativeElement.onerror = () => {
      this.failed.emit();
    };

    this.elRef.nativeElement.src = src;
  }
}
