import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeRemaining'
})
export class TimeRemainingPipe implements PipeTransform {

  transform(value: StringOrDateOrNull): string | void {
    if (!value) { return; }

    const futureDate = new Date(value);
    const pastDate = new Date();

    if (pastDate < futureDate) {
      const secondsDiff = (futureDate.getTime() - pastDate.getTime()) / 1000;
      const days = Math.floor(secondsDiff / 86400);
      const hours = Math.floor((secondsDiff % 86400) / 3600) ;
      const mins = Math.floor((secondsDiff % 86400 % 3600) / 60);
      return getRemainingText(days, hours, mins);
    }
  }
}


function getRemainingText(days: number, hours: number, mins: number): string {
  if (days) {
    if (hours) {
      return `${days} ${days === 1 ? 'day' : 'days'} ${hours} ${hours === 1 ? 'hr' : 'hrs'}`;
    } else {
      return `${days} ${days === 1 ? 'day' : 'days'}`;
    }
  }

  if (hours) {
    if (mins) {
      return `${hours} ${hours === 1 ? 'hr' : 'hrs'} ${mins} ${mins === 1 ? 'min' : 'mins'}`;
    } else {
      return `${hours} ${hours === 1 ? 'hr' : 'hrs'}`;
    }
  }

  if (mins) {
    return `${mins} ${mins === 1 ? 'min' : 'mins'}`;
  } else {
    return 'less 1 min';
  }
}
