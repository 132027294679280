import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeRemainingPipe } from './time-remaining.pipe';



@NgModule({
  declarations: [TimeRemainingPipe],
  exports: [TimeRemainingPipe],
  imports: [
    CommonModule
  ]
})
export class TimeRemainingModule { }
