<!-- Driver img -->
<div class="driver-photo-wpapper" *ngIf="hasDriver">
    <img class="driver-photo" 
        [class.hidden]="isDriverImgLoading || !driverImg"  
        [appImage]="driverImg"
        (loaded)="isDriverImgLoading = false"
        (failed)="isDriverImgHasError = true"
    />
    <img *ngIf="isDriverImgLoading || !driverImg" class="driver-photo" src="/assets/graphics/no-driver-photo.png"/>
    <div class="blackout blackout-white" *ngIf="isDriverImgLoading && !isDriverImgHasError">
        <div class="spinner spinner-sm"></div>
    </div>
</div>
<!-- //END Driver img -->
<!-- Vehicle img -->
<div class="relative">
    <img class="car-photo" [class.hidden]="isVehicleImgLoading"
    [appImage]="vehicleImg"
    (loaded)="isVehicleImgLoading = false"
    (failed)="isVehicleImgHasError = true"

    />
    <img class="car-photo" src="/assets/graphics/no-car-photo.png" *ngIf="isVehicleImgLoading"/>
    <div class="blackout blackout-white" *ngIf="isVehicleImgLoading && !isVehicleImgHasError">
        <div class="spinner spinner-sm"></div>
    </div>
</div>
<!-- //END Vehicle img -->
