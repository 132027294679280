import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import { LocationDto, ShareDataDto, ShareDataTripDto, SharedDataDriverDto, SharedDataVehicleDto } from '../../dto/share-data.dto';
import { LatLng } from '../../models/common.model';
import { DriverModel } from '../../models/driver.model';
import { HCPCompany, SharedDataModel } from '../../models/shared-data.model';
import { TripModel } from '../../models/trip.model';
import { VehicleModel } from '../../models/vehicle.model';
import { AbstractLoader } from '../abstract.loader';
import { DestinationModel } from '../../models/destination.model';

@Injectable({
  providedIn: 'root',
})
export class SharedDataLoader extends AbstractLoader {
  constructor(private readonly http: HttpClient) {
    super();
  }

  public loadSharedData(shareId: string): Observable<SharedDataModel | null> {
    return this.http.get<ShareDataDto>(this.getUrl('{apiUrl}/publiclinksaccess/{shareId}', { shareId }))
    .pipe(map((sharedDataDto) => {
      if (!sharedDataDto) { return null; }

      return {
        isHCPDriver: !!sharedDataDto.plannedArrivalTime,
        trip: sharedDataDto.trip && this.mapTrip(sharedDataDto.trip),
        vehicle: sharedDataDto.vehicle && this.mapVehicle(sharedDataDto.vehicle),
        driver:  sharedDataDto.driver && this.mapDriver(sharedDataDto.driver),
        destination: this.mapDestination(sharedDataDto.destination),
        expirationTime: sharedDataDto.linkExpirationTime,
        plannedArrivalTime: sharedDataDto.plannedArrivalTime,
        hcpCompany: this.mapHCPCompany(sharedDataDto),
      };
    }));
  }

  private mapDriver(driverDto: SharedDataDriverDto): DriverModel {
    return {
      name: [driverDto.firstName, driverDto.lastName].filter(notEmpty => !!notEmpty).join(' '),
    };
  }

  private mapVehicle(vehicleDto: SharedDataVehicleDto): VehicleModel {
    return {
      name: [vehicleDto.make, vehicleDto.model, vehicleDto.year].filter((notEmpty) => !!notEmpty).join(' '),
      licensePlate: vehicleDto.licensePlate,
      latLng: vehicleDto.location && { lat: vehicleDto.location.lat, lng: vehicleDto.location.lng },
      heading: vehicleDto.location?.heading?.value || 0,
      address: vehicleDto.location?.address?.formattedAddress,
    };
  }

  private mapTrip(tripDto: ShareDataTripDto): TripModel {
    return {
      completed: tripDto.completed,
      startAddress: getAddress(tripDto.startLocation),
      endAddress: getAddress(tripDto.endLocation),
      startLatLng: getLocation(tripDto.startLocation),
      endLatLng: getLocation(tripDto.endLocation),
      encodedPolyline: tripDto.polyline,
    };
  }

  private mapDestination(destinationDto: LocationDto): DestinationModel {
    return {
      latLng: getLocation(destinationDto),
      address: getAddress(destinationDto),
    };
  }

  private mapHCPCompany(shared: ShareDataDto): HCPCompany | undefined {
    if (shared.businessLogoUrl || shared.businessName || shared.businessPhone) {
      return {
        name: shared.businessName,
        logo: shared.businessLogoUrl,
        phone: parsePhoneNumber(shared.businessPhone),
      };
    }

    return; // this is asked to do by ts-lint
  }
}

function getAddress(dto: LocationDto): string | undefined {
  if (dto && dto.address && dto.address.formattedAddress) {
    return dto.address.formattedAddress;
  }
  return;
}

function getLocation(dto: LocationDto): LatLng | undefined {
  if (dto && typeof dto.lat === 'number' && typeof dto.lng === 'number'  ) {
    return {lat: dto.lat, lng: dto.lng };
  }
  return; // this is asked to do by ts-lint
}

function parsePhoneNumber(phoneNumber: string): string | undefined {
  if (phoneNumber) {
    const digits = phoneNumber.replace(/[^\d]/g, '');
    if (phoneNumber[0] === '+') {
      return digits;
    }
    // in another case just add 1 before number;
    return `1${digits}`;
  }

  return; // this is asked to do by ts-lint
}
