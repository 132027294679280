export type TColorName = 'brand-1' | 'brand-2'; // | 'status-0' | 'status-2' | 'status-3' | 'status-4';

const COLORS_MAP: {
  [colorName in TColorName]: string;
} = {
  'brand-1': '#00baf2',
  'brand-2': '#0079b8',
  // 'status-0': '#4a4a4a',
  // 'status-2': '#f6a623',
  // 'status-3': '#d43213', // red
  // 'status-4': '#dcdcdc',
};

export const getColor = (colorName: TColorName): string => {
  const color = COLORS_MAP[colorName];

  if (!color) {
    console.error('[COLOR PALETTE] Color Not Found', colorName);
    return COLORS_MAP['brand-1'];
  }

  return color;
};
