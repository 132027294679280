import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { MapControlPosition } from '../map-control-container/map-control-position';

@Component({
  selector: 'app-map-zoom-controls',
  templateUrl: './map-zoom-controls.component.html',
  styleUrls: ['./map-zoom-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapZoomControlsComponent implements OnInit {

  @Output()
  public pressed = new EventEmitter<void>();

  public position: MapControlPosition = MapControlPosition.RIGHT_TOP;

  constructor(private readonly map: GoogleMap) { }

  ngOnInit(): void {
  }

  public onChangeZoomPressed(diff: number): void {
    const gmap = this.map.googleMap;
    if (gmap) {
      gmap.setZoom(gmap.getZoom() + diff);
    }

    this.pressed.emit();
  }
}
