<ng-template [ngIf]="latLng">
 
  <map-marker *ngIf="mapIcon$|async as iconOpts"
    [position]="latLng"
    [options]="iconOpts"
  ></map-marker>

  <!-- <map-marker
  [position]="latLng"
  [options]="arrowShapeOpt()"
></map-marker> -->
</ng-template>