import { NgModule } from '@angular/core';
import { MapZoomControlsComponent } from './map-zoom-controls.component';
import { MapControlContainerModule } from '../map-control-container/map-control-container.module';

@NgModule({
  declarations: [MapZoomControlsComponent],
  exports: [MapZoomControlsComponent],
  imports: [
    MapControlContainerModule,
  ]
})
export class MapZoomControlsModule { }
