import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SharedDataLoader } from 'src/app/core/loaders/shared-data/shared-data.loader';
import { DriverModel } from 'src/app/core/models/driver.model';
import { TripModel } from 'src/app/core/models/trip.model';
import { VehicleModel } from 'src/app/core/models/vehicle.model';
import { googleMapsStyles } from 'src/googlemap.styles';
import { exhaustMap, finalize, shareReplay, takeUntil } from 'rxjs/operators';
import { GoogleMap } from '@angular/google-maps';
import { IS_RETINA } from 'src/app/utils/common';
import { Observable, Subject, timer } from 'rxjs';
import { HCPCompany, SharedDataModel } from 'src/app/core/models/shared-data.model';
import { DestinationModel } from '../../../../core/models/destination.model';
import { MapIconLoader } from 'src/app/core/loaders/map-icon/map-icon.loader';

const REFRESH_INTERVAL = 15000; // 15 seconds

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss', './blackout.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit, OnDestroy {

  @ViewChild(GoogleMap)
  public googleMap: GoogleMap | undefined;

  public mapOptions: google.maps.MapOptions = {
    disableDefaultUI: true,
    styles: googleMapsStyles,
  };

  public tripsStartOpts: google.maps.MarkerOptions = {
    icon: {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 6,
      strokeColor: '#737373', // icon color, extracted from svg file $color-fixed-icon
      fillOpacity: 1,
      fillColor: '#FFF',
    },
  };

  public destinationOpts: google.maps.MarkerOptions = {
    icon: {
      url: '/assets/map/pin@2x.png',
      scaledSize: new google.maps.Size(36, 36)
    },
  };

  public parkedIconOpts$!: Observable<google.maps.MarkerOptions>;

  public vehicle: VehicleModel | undefined;
  public driver: DriverModel | undefined;
  public trip: TripModel | undefined;
  public destination: DestinationModel | undefined;
  public expirationTime: Date | null = null;
  public plannedArrivalTime: string | undefined;
  public shareId: string | undefined;
  public isHCPDriver = false;
  public hcpCompany: HCPCompany | undefined;

  public errorMessage: string | undefined;
  public loading = true;

  public isVehicleCardOpen = false;

  private expired$ = new Subject<void>();

  private isInitialFitToBoundsHappened = false;

  private allowFollowMapToTheVehicle = true;

  private zoomEventUnsubscriber: (() => void) | undefined;

  constructor(
    private shareDataLoader: SharedDataLoader,
    private mapIconLoader: MapIconLoader,
    private chRef: ChangeDetectorRef,
  ) { }

  public ngOnInit(): void {
    const shareId = this.getShareId();
    if (!shareId) {
      this.loading = false;
      this.errorMessage = 'Invalid share location link, please check link again';
    } else {
      this.shareId = shareId;
      this.loading = true;
      this.loadShareData(shareId);
      this.parkedIconOpts$ = this.mapIconLoader.loadVehicleParkedIcon(shareId);
    }
  }

  public ngOnDestroy(): void {
    if (this.zoomEventUnsubscriber) {
      this.zoomEventUnsubscriber();
    }
  }

  public isShowExpiredPlug(): boolean {
    return !this.errorMessage &&
      !this.loading &&
      (!this.expirationTime || isExpired(this.expirationTime));
  }

  public onRecenterMapPressed(): void {
    this.followTheVehicle();
    this.allowFollowMapToTheVehicle = true;
  }

  public onMapDragStart(): void {
    this.mapWasTouchedByUser();
  }

  public onZoomControlPressed(): void {
    this.mapWasTouchedByUser();
  }

  public mapWasTouchedByUser(): void {
    this.allowFollowMapToTheVehicle = !this.isInitialFitToBoundsHappened;
  }

  private fitToBounds(): void {
    const bounds = new google.maps.LatLngBounds();
    [
      this.trip?.startLatLng,
      this.trip?.endLatLng,
      this.vehicle?.latLng,
      this.destination?.latLng,
    ]
    .forEach((latLng) => latLng && bounds.extend(latLng));

    if (!bounds.isEmpty()) {
      this.googleMap?.fitBounds(bounds);
    }
  }

  private loadShareData(shareId: string): void {
    timer(0, REFRESH_INTERVAL).pipe(
      takeUntil(this.expired$),
      exhaustMap(() => this.shareDataLoader.loadSharedData(shareId).pipe(
        finalize(() => {
          this.loading = false;
          this.chRef.markForCheck();
        }))))
      .subscribe({
        next: (sharedData) => {
          this.handleSharedData(sharedData);
        },
        error: (error) => {
          this.errorMessage = error.error.error && error.error.error.message
          || error.error.Message
          || 'Unknown error';
        },
      });
  }

  private handleSharedData(sharedData: SharedDataModel | null): void {
    if (sharedData) {
      this.trip = sharedData.trip;
      this.driver = sharedData.driver;
      this.vehicle = sharedData.vehicle;
      this.destination = sharedData.destination;
      this.expirationTime = sharedData.expirationTime ? new Date(sharedData.expirationTime) : null;
      this.plannedArrivalTime = sharedData.plannedArrivalTime;
      this.isHCPDriver = sharedData.isHCPDriver;
      this.hcpCompany = sharedData.hcpCompany;

      if (!this.isInitialFitToBoundsHappened) {
        this.fitToBounds();
        if (this.googleMap?.googleMap) {
          this.zoomEventUnsubscriber = attachZoomChangeEventsToMap(this.googleMap?.googleMap, () => {
            this.mapWasTouchedByUser();
          });
        }
        this.isInitialFitToBoundsHappened = true;
      } else if (this.allowFollowMapToTheVehicle) {
        this.followTheVehicle();
      }
    } else {
      this.trip = undefined;
      this.driver = undefined;
      this.vehicle = undefined;
      this.destination = undefined;
      this.expirationTime = null;
      this.plannedArrivalTime = undefined;
      this.isHCPDriver = false;
    }

    if (!this.expirationTime || isExpired(this.expirationTime)) {
      this.expired$.next();
    }
  }

  private followTheVehicle(): void {
    if (this.trip?.completed) {
      this.fitToBounds();
    } else if (this.vehicle?.latLng) {
      this.googleMap?.panTo(this.vehicle?.latLng);
    }
  }

  private getShareId(): string {
    // while we don't use angular routing - we should parse this by ourselves.
    return window.location.pathname.split('/')[1];
  }
}

function isExpired(expTime: Date): boolean {
  return expTime.getTime() <= Date.now();
}

function attachZoomChangeEventsToMap(gmap: google.maps.Map, listener: () => void): () => void {
  const zoomChangeHandler = () => {
    listener();
  };

  let tappedTwice = false;
  const touchListener = (e: any) => {
    if (e.touches && e.touches.length === 2 || tappedTwice) {
      zoomChangeHandler();
    } else {
      tappedTwice = true;
      setTimeout(() => tappedTwice = false, 410);
    }
  };
  const mapDiv = gmap.getDiv();
  if (mapDiv) {
    mapDiv.addEventListener('wheel', zoomChangeHandler);
    mapDiv.addEventListener('dblclick', zoomChangeHandler);
    mapDiv.addEventListener('touchstart', touchListener);
  }

  return () => {
    if (mapDiv) {
      mapDiv.removeEventListener('wheel', zoomChangeHandler);
      mapDiv.removeEventListener('dblclick', zoomChangeHandler);
      mapDiv.removeEventListener('touchstart', touchListener);
    }
  };
}
