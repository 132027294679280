import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapControlContainerComponent } from './map-control-container.component';



@NgModule({
  declarations: [MapControlContainerComponent],
  exports: [MapControlContainerComponent],
  imports: [
    CommonModule
  ]
})
export class MapControlContainerModule { }
