import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { MapControlPosition } from '../map-control-container/map-control-position';

@Component({
  selector: 'app-map-expand-control',
  templateUrl: './map-expand-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapExpandControlComponent {
  public position: MapControlPosition = MapControlPosition.RIGHT_BOTTOM;

  constructor(private readonly map: GoogleMap, private chRef: ChangeDetectorRef) { }

  public isExpanded(): boolean {
    return !!document.fullscreenElement;
  }

  public onToggleFullscreenPressed(): void {
    if (this.map.googleMap) {
      const elem = this.map.googleMap.getDiv();
      if (!document.fullscreenElement) {
        elem.requestFullscreen()
        .then(() => this.chRef.markForCheck())
        .catch(err => {
          alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
        });
      } else {
        document.exitFullscreen().then(() => {
          this.chRef.markForCheck();
        });
      }
    }
  }
}
