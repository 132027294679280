import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PolylineComponent } from './polyline.component';
import { GoogleMapsModule } from '@angular/google-maps';



@NgModule({
  declarations: [PolylineComponent],
  exports: [PolylineComponent],
  imports: [
    CommonModule,
    GoogleMapsModule,
  ]
})
export class PolylineModule { }
