import { interpolateStr } from 'src/app/utils/string';
import { environment } from 'src/environments/environment';

export abstract class AbstractLoader {

  protected getUrl(tpl: string, params: Record<string, string>): string {
    return interpolateStr(tpl, {
      ...this.getEndpoints(),
      ...params,
    });
  }

  protected getEndpoints(): { apiUrl: string } {
    return {
      apiUrl: environment.apiUrl,
    };
  }
}
