import { Pipe, PipeTransform } from '@angular/core';
import { TColorName, getColor } from 'src/app/core/config/colors/colors.palette';

@Pipe({
  name: 'color',
})
export class ColorPipe implements PipeTransform {
  transform(colorName: TColorName): any {
    return getColor(colorName);
  }
}
