<div>
  <!-- HCP Company block -->
  <div *ngIf="hcpCompany" class="hcp-company-info">
    <img *ngIf="hcpCompany.logo" class="hcp-company-logo" [src]="hcpCompany.logo"/>
    <div *ngIf="hcpCompany.name" class="hcp-company-name">
      {{ hcpCompany.name }}
    </div>
    <a *ngIf="hcpCompany.phone" class="hcp-company-phone" (click)="stopPropagation($event)" [href]="'tel:+' + hcpCompany.phone">{{hcpCompany.phone | mask: '+0 000 000 0000'}}</a>
  </div>
  <!-- // END HCP Company block -->
  <div class="car-with-driver-photo-wrapper">
    <app-img-vehicle-with-driver [hasDriver]="!!driver" [shareId]="shareId"></app-img-vehicle-with-driver>
  </div>

  <table class="info-table">
    <tr *ngIf="vehicle?.name">
      <th>Vehicle:</th>
      <td>{{vehicle?.name}}</td>
    </tr>
    <tr *ngIf="vehicle?.licensePlate">
      <th>Plate Number:</th>
      <td>{{vehicle?.licensePlate}}</td>
    </tr>

    <ng-template [ngIf]="!isHCPDriver" [ngIfElse]="hcpBlockTpl">
      <tr *ngIf="driver?.name">
        <th>Driver:</th>
        <td>{{driver?.name}}</td>
      </tr>
      <tr *ngIf="!isTripCompleted && startAddress">
        <th>En Route From:</th>
        <td>
          {{startAddress}}
        </td>
      </tr>

      <tr *ngIf="(!hasTrip || isTripCompleted) && endAddress">
        <th>Parked at:</th>
        <td>
          {{endAddress}}
        </td>
      </tr>
    </ng-template>

    <ng-template #hcpBlockTpl>
      <tr *ngIf="driver?.name">
        <th>Pro:</th>
        <td>{{driver?.name}}</td>
      </tr>
      <tr *ngIf="jobStartTime">
        <th>Job Start Time:</th>
        <td>
          {{jobStartTime | dateformat: 'shortTime'}}
        </td>
      </tr>
      <tr *ngIf="jobAddress">
        <th>Job Address:</th>
        <td>
          {{jobAddress}}
        </td>
      </tr>
    </ng-template>
  </table>
</div>
