import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';
import { loadImage, loadImgIntoCanvas } from 'src/app/utils/load-image';
import { ImageLoader } from '../image/image.loader';

const NO_CAR_PHOTO = '/assets/graphics/no-car-photo.png';

type IconWithCirclePhotoPrams = {
  blancIcon: string;
  circlePadding: number;
  photoScale: number;
  photoPaddingLeft: number;
  fallbackPhoto: string;
  markerOptions: google.maps.MarkerOptions;
  iconOptions: Partial<google.maps.Icon>;
};
const ENROUTE_ICON_PARAMS: IconWithCirclePhotoPrams = {
  blancIcon: '/assets/map/enroute.svg',
  fallbackPhoto: NO_CAR_PHOTO,
  photoScale: .65,
  photoPaddingLeft: .3,
  circlePadding: .27,
  markerOptions: {
    clickable: false,
  },
  iconOptions: {
    scaledSize: new google.maps.Size(60, 60),
    anchor: new google.maps.Point(30, 30),
  }
};

const PARKED_ICON_PARAMS: IconWithCirclePhotoPrams = {
  blancIcon: '/assets/map/parked-blanc@2x.png',
  circlePadding: .1,
  photoScale: 1,
  photoPaddingLeft: .1,
  fallbackPhoto: NO_CAR_PHOTO,
  markerOptions: {
    clickable: false,
  },
  iconOptions: {
    scaledSize: new google.maps.Size(36, 54),
  }
};

@Injectable({
  providedIn: 'root',
})
export class MapIconLoader {

  private cache = new Map<string, Observable<google.maps.MarkerOptions>>();

  constructor(private imgLoader: ImageLoader) {}

  public loadVehicleEnrouteIcon(shareId: string, heading: number): Observable<google.maps.MarkerOptions> {
    return this.loadMarkerIcon(ENROUTE_ICON_PARAMS, shareId, heading);
  }

  public loadVehicleParkedIcon(shareId: string): Observable<google.maps.MarkerOptions> {
    return this.loadMarkerIcon(PARKED_ICON_PARAMS, shareId);
  }

  private loadMarkerIcon(params: IconWithCirclePhotoPrams, shareId: string, heading = 0): Observable<google.maps.MarkerOptions> {
    const cacheKey = `${params.blancIcon}/${shareId}/${heading}`;
    if (!this.cache.has(cacheKey)) {
      this.cache.set(cacheKey, this.loadIconWithPhoto(params, shareId, heading).pipe(map((base64Img) => ({
        ...params.markerOptions,
        icon: {
          url: base64Img,
          ...params.iconOptions,
        }
      })),
      shareReplay()));
    }

    return this.cache.get(cacheKey) as Observable<google.maps.MarkerOptions>;
  }

  private loadIconWithPhoto(params: IconWithCirclePhotoPrams, shareId: string, heading = 0): Observable<string> {
    return combineLatest([
      loadImgIntoCanvas(params.blancIcon, { rotation: heading }),
      this.imgLoader.loadVehicleImg(shareId).pipe(
        switchMap((imgBase64: string) => loadImage(imgBase64)),
        catchError(() => loadImage(params.fallbackPhoto)),
      ),
    ]).pipe(
      map(([blankIconCanvas, vehicleImg]: [HTMLCanvasElement, HTMLImageElement]) => {
        const maskCanvas = document.createElement('canvas');
        maskCanvas.width = blankIconCanvas.width;
        maskCanvas.height = blankIconCanvas.width;
        const maskCtx = maskCanvas.getContext('2d');
        if (maskCtx) {
          maskCtx.fillStyle = 'red';
          maskCtx.beginPath();

          maskCtx.arc( // draw circle
            maskCanvas.width / 2, // center x
            maskCanvas.height / 2, // center y
            maskCanvas.width / 2 - maskCanvas.width * params.circlePadding, // radius
             0, 2 * Math.PI);

          maskCtx.fill();
          maskCtx.closePath();
          maskCtx.globalCompositeOperation = 'source-in';
          const scale = (maskCanvas.width / vehicleImg.width) * params.photoScale; // vehicle img scale to blank (container) icon
          maskCtx.drawImage(
            vehicleImg,
            maskCanvas.width * params.photoPaddingLeft, // x
            (maskCanvas.height - vehicleImg.height * scale) / 2, // y
            vehicleImg.width * scale, // width
            vehicleImg.height * scale); // height
        }


        const ctx = blankIconCanvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(maskCanvas, 0, 0);
        }

        return blankIconCanvas.toDataURL();
      }),
    );
  }
}
