import { Directive, HostListener, Input } from '@angular/core';
import { AnalyticsService, TAnalyticsEvents } from 'src/app/core/analytics/analytics.service';

@Directive({ selector: '[appTrackEvent]' })
export class TrackEventDirective {
  constructor(private readonly analytics: AnalyticsService) {}

  @Input('appTrackEvent') eventName: TAnalyticsEvents | undefined;

  @HostListener('click', ['$event']) onClick(): void {
    if (this.eventName){
      this.analytics.trackEvent(this.eventName);
    }
  }
}
