import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-common-layout',
  templateUrl: './common-layout.component.html',
  styleUrls: ['./common-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonLayoutComponent implements OnInit {

  @Input()
  public logoContentRef!: TemplateRef<any> | null;

  constructor() { }

  ngOnInit(): void {
  }

}
