import { NgModule } from '@angular/core';
import { TrackEventDirective } from './track-event.directive';

const directives = [
  TrackEventDirective,
];


@NgModule({
  declarations: directives,
  exports: directives,
})
export class DirectivesCommonModule { }
