import { AfterContentInit, ChangeDetectionStrategy, ElementRef, Input, OnDestroy } from '@angular/core';
import { Component } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { MapControlPosition } from './map-control-position';

@Component({
  selector: 'app-map-control-container',
  templateUrl: './map-control-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapControlContainerComponent implements AfterContentInit, OnDestroy {

  @Input()
  public position: MapControlPosition = MapControlPosition.RIGHT_BOTTOM;

  private controlIdx = -1;

  constructor(private elementRef: ElementRef, private readonly map: GoogleMap) {}

  public ngAfterContentInit(): void {
    this.controlIdx = (this.getMap()).controls[this.position].push(this.elementRef.nativeElement) - 1;
  }

  public ngOnDestroy(): void {
    this.removeControl();
  }

  private removeControl(): void {
    if (this.controlIdx >= 0) {
      (this.getMap()).controls[this.position].removeAt(this.controlIdx);
      this.controlIdx = -1;
    }
  }

  private getMap(): google.maps.Map{
    return this.map.googleMap as google.maps.Map;
  }
}
